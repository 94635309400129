// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-championships-index-js": () => import("./../../../src/pages/championships/index.js" /* webpackChunkName: "component---src-pages-championships-index-js" */),
  "component---src-pages-checkout-index-js": () => import("./../../../src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-join-form-js": () => import("./../../../src/pages/join/form.js" /* webpackChunkName: "component---src-pages-join-form-js" */),
  "component---src-pages-kit-index-js": () => import("./../../../src/pages/kit/index.js" /* webpackChunkName: "component---src-pages-kit-index-js" */),
  "component---src-pages-routes-index-js": () => import("./../../../src/pages/routes/index.js" /* webpackChunkName: "component---src-pages-routes-index-js" */),
  "component---src-pages-sessions-index-js": () => import("./../../../src/pages/sessions/index.js" /* webpackChunkName: "component---src-pages-sessions-index-js" */),
  "component---src-pages-venues-index-js": () => import("./../../../src/pages/venues/index.js" /* webpackChunkName: "component---src-pages-venues-index-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-championship-js": () => import("./../../../src/templates/championship.js" /* webpackChunkName: "component---src-templates-championship-js" */),
  "component---src-templates-committee-page-js": () => import("./../../../src/templates/committee-page.js" /* webpackChunkName: "component---src-templates-committee-page-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-events-calendar-js": () => import("./../../../src/templates/events-calendar.js" /* webpackChunkName: "component---src-templates-events-calendar-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-join-page-js": () => import("./../../../src/templates/join-page.js" /* webpackChunkName: "component---src-templates-join-page-js" */),
  "component---src-templates-kit-js": () => import("./../../../src/templates/kit.js" /* webpackChunkName: "component---src-templates-kit-js" */),
  "component---src-templates-route-js": () => import("./../../../src/templates/route.js" /* webpackChunkName: "component---src-templates-route-js" */),
  "component---src-templates-rules-page-js": () => import("./../../../src/templates/rules-page.js" /* webpackChunkName: "component---src-templates-rules-page-js" */),
  "component---src-templates-session-js": () => import("./../../../src/templates/session.js" /* webpackChunkName: "component---src-templates-session-js" */),
  "component---src-templates-standard-page-js": () => import("./../../../src/templates/standard-page.js" /* webpackChunkName: "component---src-templates-standard-page-js" */),
  "component---src-templates-venue-js": () => import("./../../../src/templates/venue.js" /* webpackChunkName: "component---src-templates-venue-js" */)
}

